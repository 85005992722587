import React from "react";

const SpiritualJourney = () => {
  return (
    <>
      {/*==================== start ====================*/}
      <div
        className='work-process-area bg-position-right pt-120 '
        style={{ backgroundImage: 'url("./assets/img/bg/16.png")' }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center pb-5'>
                <h2 className='title'>
                हमारी सेवाएँ और समर्थन
                </h2>
              
              </div>
            </div>
          </div>
          <div className='work-process-area-inner-2'>
            <div className='row'>

              <div className='col-lg-3 col-md-6 pt-lg-2 pt-5'>
                <div className='single-work-process-inner style-2 text-center'>
                  <img
                    className='line-img'
                    src='Assets/imgs/temp/29.png'
                    alt='img'
                  />
                  <div className='thumb mb-3'>
                    <img src='Assets/imgs/home/donation.png' width="50px" height="50px" alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>दान करें</h5>
                    <p className='content'>
                    गौ सेवा, आध्यात्मिक शिक्षा और समाज कल्याण के कार्यों में सहयोग के लिए कृपया दान करें।
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-md-6 pt-lg-2 pt-5'>
                <div className='single-work-process-inner style-2 text-center'>
                  <img
                    className='line-img'
                    src='Assets/imgs/temp/30.png'
                    alt='img'
                  />
                  <div className='thumb mb-3 pt-lg-2 pt-5'>
                    <img src='Assets/imgs/home/cow.png' width="50px" height="50px" alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>गाय </h5>
                    <p className='content'>
                    गाय एक पवित्र और करुणामयी प्राणी है, जो हमारे जीवन में आध्यात्मिक और भौतिक दोनों रूपों में महत्वपूर्ण योगदान देती है।
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-md-6 pt-lg-2 pt-5'>
                <div className='single-work-process-inner style-2 text-center'>
                  <img
                    className='line-img'
                    src='Assets/imgs/temp/29.png'
                    alt='img'
                  />
                  <div className='thumb mb-3'>
                    <img src='Assets/imgs/home/monk.png' width="50px" height="50px" alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>गुरु </h5>
                    <p className='content'>
                    आध्यात्मिक गुरु, जो आध्यात्मिक ज्ञान और साधना के माध्यम से शिष्य को आत्मा की गहराई और जीवन के उद्देश्य को समझने में मदद करते हैं।
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-md-6 pt-lg-2 pt-5'>
                <div className='single-work-process-inner style-2 text-center'>
                  <img
                    className='line-img'
                    src='Assets/imgs/temp/29.png'
                    alt='img'
                  />
                
                  <div className='thumb mb-3'>
                    <img src='Assets/imgs/home/help.png' width="50px" height="50px" alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>मदद करें</h5>
                    <p className='content'>
                    आप किस प्रकार की मदद चाहते हैं? मैं किसी भी सवाल या जरूरत में आपकी सहायता करने के लिए यहाँ हूँ।
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default SpiritualJourney;
