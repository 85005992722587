import React, { useState } from 'react';
import {netra} from '../Utils/netra'

const HealthCheckup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImage('');
  };

  return (
    <>
      <div className='container'>
        <div className="container pb-4 pt-100">
          <div className='section-title text-center'>
            <h2 className='title text-center'>नेत्र शिविर फोटो गैलरी</h2>
            <p className='custom-p text-center'>नेत्र शिविर का आयोजन स्वामी गुरु कृपानंद जी महाराज द्वारा समाज सेवा के महत्वपूर्ण कार्यों में से एक है। नेत्र शिविर का उद्देश्य नेत्रहीनता और नेत्र संबंधी रोगों से पीड़ित लोगों को निःशुल्क चिकित्सा सहायता प्रदान करना है। इस शिविर में अनुभवी चिकित्सकों द्वारा आंखों की जांच, उपचार और जरूरतमंदों को मुफ्त चश्मे, दवाइयाँ, और कभी-कभी ऑपरेशन की सुविधाएँ भी दी जाती हैं।</p>
          </div>
        </div>

        <div className='row'>
          {netra.map((image, index) => (
            <div className='col-md-4 col-12 my-3' key={index}>
              <img
                src={image}
                className='thumbimg'
                alt={`Gallery image ${index + 1}`}
                onClick={() => openModal(image)}
                style={{ cursor: 'pointer' }} // Change cursor to pointer for better UX
              />
            </div>
          ))}
        </div>

        {/* Modal for displaying the clicked image */}
        {isOpen && (
          <div className='modal' onClick={closeModal}>
            <div className='modal-frame'>
              <span className='close' onClick={closeModal}>&times;</span>
              <img className='modal-content' src={selectedImage} alt='Selected' />
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        .modal {
          display: flex;
          position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.9);
          align-items: center;
          justify-content: center;
        }
        .modal-frame {
          width: 100%;
          max-width: 90%;
          margin: auto;
          background-color: #fff;
          padding: 20px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .modal-content {
          max-width: 100%;
          max-height: 80vh;
          border-radius: 10px;
        }
        .close {
          position: absolute;
          top: 20px;
          right: 30px;
          color: #ffffff;
          font-size: 40px;
          cursor: pointer;
        }
        
        @media (min-width: 992px) {
          .modal-frame {
            width: 30%; /* Show half the screen width on large devices */
          }
        }

        @media (max-width: 991px) {
          .modal-frame {
            width: 100%; /* Full width on smaller screens */
          }
        }
      `}</style>
    </>
  );
};


export default HealthCheckup