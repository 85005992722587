import React from 'react'
import DonationHero from '../Components/DonationHero'

const Donation = () => {
  return (
 <>
    <DonationHero/>
 </>
  )
}

export default Donation