
export const bhagvadgita = [
    'Assets/imgs/gallery/santseva/1.png',
    'Assets/imgs/gallery/santseva/2.png',
    'Assets/imgs/gallery/santseva/3.png',
    'Assets/imgs/gallery/santseva/4.png',
    'Assets/imgs/gallery/santseva/5.png',
    'Assets/imgs/gallery/santseva/6.png',
    'Assets/imgs/gallery/santseva/7.png',
   
  ];

  