export const eventdata = [
    {
        img:"/Assets/imgs/temp/Untitled design (80).png",
        allimg:[
            { src: "/Assets/imgs/temp/banner.jpg", alt: "Image 1" },
          ],
        title : "श्रीमद् भागवत कथा" ,
        description : "स्वामी गुरु कृपानंद जी महाराज के सान्निध्य में श्रीमद्भागवत कथा का आयोजन...",
        fulldescription : "स्वामी गुरु कृपानंद जी महाराज के सान्निध्य में श्रीमद्भागवत कथा का आयोजन 06 अक्टूबर से 12 अक्टूबर 2024 तक गुरुकृपा गौ सेवा धाम, आनंद वाटिका, वृंदावन में होगा। कथा का समय दोपहर 2:15 से रहेगा। कथा के यजमान हरियाणा से नारायण सिंह जी हैं। अधिक जानकारी के लिए संपर्क करें: 7017421499 (Acharya शिव गौतम: 6397468956)।",
        date : " 06 अक्टूबर 2024 - 12 अक्टूबर 2024",
        time : "दोपहर 2 से हरि इच्छा तक",
        address : "गुरुकृपा गौ सेवा धाम, आनंद वाटिका, वृंदावन",
        category : "upcomming"
    },
    {
        img:"/Assets/imgs/temp/Untitled design (80).png",
        allimg:[ { src: "/Assets/imgs/temp/banner1.jpg", alt: "Image 1" },],
        title : "बृज चौरासी कोस दर्शन यात्रा " ,
        description : "बृज चौरासी कोस दर्शन यात्रा का आयोजन 25 से 31 दिसंबर 2024 तक स्वामी",
        fulldescription :"बृज चौरासी कोस दर्शन यात्रा का आयोजन 25 से 31 दिसंबर 2024 तक स्वामी गुरु कृपानन्द जी महाराज के पावन सानिध्य में होगा। इस पवित्र यात्रा के संयोजक पं. कन्हैया पाण्डेय (सुभाष) हैं। संपर्क के लिए श्रीमती कविता माहेश्वरी (मो. 6378303676) और श्रीमान ऐश्वर्य जी (मो. 9636936926) से जुड़ा जा सकता है।  स्थान: गुरुकृपा गौ सेवा धाम, आनन्द वाटिका, वृन्दावन यात्रा की विशेषताएँ:  7 दिन की यात्रा (AC/गीजर युक्त सुविधाएँ) ,रहने, खाने और गाड़ी की पूरी व्यवस्था ,यात्रा शुल्क: ₹11000 प्रति व्यक्ति इस यात्रा का हिस्सा बनकर ब्रजभूमि के पवित्र दर्शन करें और स्वामी जी के आशीर्वाद से अपनी आत्मा को शुद्ध करें।",
        date : " 25 दिसंबर 2024 - 31 दिसंबर 2024",
        time : "09:00 am to 07:00 pm",
        address : "गुरुकृपा गौ सेवा धाम, आनंद वाटिका, वृंदावन",
        category : "upcomming"
    },
    // {
    //     img:"/Assets/imgs/temp/Untitled design (80).png",
    //     title : "श्रीमद्भागवत कथा" ,
    //     description : "पूज्य डॉ. संजय कृष्ण सलिल जी महाराज द्वारा लाइव श्रीमद भागवत कथा देखें",
    //     date : " 25 September 2024 - 02 October 2024",
    //     time : "04:00 pm to 07:00 pm",
    //     address : "कोरस बैंक्वेट एण्ड कन्वेंशन, महरौली रोह, अनामिका एक्लेव, सेक्टर-44. गुरुग्राम, हरियाणा",
    //     category : "upcomming"
    // },
    // {
    //     img:"/Assets/imgs/temp/Untitled design (80).png",
    //     title : "श्रीमद्भागवत कथा" ,
    //     description : "पूज्य डॉ. संजय कृष्ण सलिल जी महाराज द्वारा लाइव श्रीमद भागवत कथा देखें",
    //     date : " 25 September 2024 - 02 October 2024",
    //     time : "04:00 pm to 07:00 pm",
    //     address : "कोरस बैंक्वेट एण्ड कन्वेंशन, महरौली रोह, अनामिका एक्लेव, सेक्टर-44. गुरुग्राम, हरियाणा",
    //     category : "completed"
    // },
    // {
    //     img:"/Assets/imgs/temp/Untitled design (80).png",
    //     title : "श्रीमद्भागवत कथा" ,
    //     description : "पूज्य डॉ. संजय कृष्ण सलिल जी महाराज द्वारा लाइव श्रीमद भागवत कथा देखें",
    //     date : " 25 September 2024 - 02 October 2024",
    //     time : "04:00 pm to 07:00 pm",
    //     address : "कोरस बैंक्वेट एण्ड कन्वेंशन, महरौली रोह, अनामिका एक्लेव, सेक्टर-44. गुरुग्राम, हरियाणा",
    //     category : "upcomming"
    // },
    // {
    //     img:"/Assets/imgs/temp/Untitled design (80).png",
    //     title : "श्रीमद्भागवत कथा" ,
    //     description : "पूज्य डॉ. संजय कृष्ण सलिल जी महाराज द्वारा लाइव श्रीमद भागवत कथा देखें",
    //     date : " 25 September 2024 - 02 October 2024",
    //     time : "04:00 pm to 07:00 pm",
    //     address : "कोरस बैंक्वेट एण्ड कन्वेंशन, महरौली रोह, अनामिका एक्लेव, सेक्टर-44. गुरुग्राम, हरियाणा",
    //     category : "completed"
    // },
    // {
    //     img:"/Assets/imgs/temp/Untitled design (80).png",
    //     title : "श्रीमद्भागवत कथा" ,
    //     description : "पूज्य डॉ. संजय कृष्ण सलिल जी महाराज द्वारा लाइव श्रीमद भागवत कथा देखें",
    //     date : " 25 September 2024 - 02 October 2024",
    //     time : "04:00 pm to 07:00 pm",
    //     address : "कोरस बैंक्वेट एण्ड कन्वेंशन, महरौली रोह, अनामिका एक्लेव, सेक्टर-44. गुरुग्राम, हरियाणा",
    //     category : "upcomming"
    // }, {
    //     img:"/Assets/imgs/temp/Untitled design (80).png",
    //     title : "श्रीमद्भागवत कथा" ,
    //     description : "पूज्य डॉ. संजय कृष्ण सलिल जी महाराज द्वारा लाइव श्रीमद भागवत कथा देखें",
    //     date : " 25 September 2024 - 02 October 2024",
    //     time : "04:00 pm to 07:00 pm",
    //     address : "कोरस बैंक्वेट एण्ड कन्वेंशन, महरौली रोह, अनामिका एक्लेव, सेक्टर-44. गुरुग्राम, हरियाणा",
    //     category : "completed"
    // },
    // {
    //     img:"/Assets/imgs/temp/Untitled design (80).png",
    //     title : "श्रीमद्भागवत कथा" ,
    //     description : "पूज्य डॉ. संजय कृष्ण सलिल जी महाराज द्वारा लाइव श्रीमद भागवत कथा देखें",
    //     date : " 25 September 2024 - 02 October 2024",
    //     time : "04:00 pm to 07:00 pm",
    //     address : "कोरस बैंक्वेट एण्ड कन्वेंशन, महरौली रोह, अनामिका एक्लेव, सेक्टर-44. गुरुग्राम, हरियाणा",
    //     category : "upcomming"
    // },
    // {
    //     img:"/Assets/imgs/temp/Untitled design (80).png",
    //     title : "श्रीमद्भागवत कथा" ,
    //     description : "पूज्य डॉ. संजय कृष्ण सलिल जी महाराज द्वारा लाइव श्रीमद भागवत कथा देखें",
    //     date : " 25 September 2024 - 02 October 2024",
    //     time : "04:00 pm to 07:00 pm",
    //     address : "कोरस बैंक्वेट एण्ड कन्वेंशन, महरौली रोह, अनामिका एक्लेव, सेक्टर-44. गुरुग्राम, हरियाणा",
    //     category : "completed"
    // }
]