import React from 'react'

const AboutMission = () => {
  return (
    <div className='container pt-100 pb-5' >
      <div className='row'>
       
        <div className='col-md-6 col-12 d-flex justify-content-center flex-column align-items-lg-start align-items-center'>
    <h2 className=' mt-lg-0 mt-5 text-start'>हमारा मिशन</h2>
    <p className='text-start' style={{lineHeight:"30px"}}>
    गुरुकृपा गो सेवा धाम में, हमारा मिशन गायों और मानवता की सेवा करना है, जो समाज को उन्नति की ओर ले जाए। हम निम्नलिखित के लिए समर्पित हैं:
    </p>
    <p className=' text-start' style={{lineHeight:"30px"}}>
   <b>गाय सेवा (गो सेवा): </b>हमारी संस्कृति में देवत्व के प्रतीक गायों को आश्रय, भोजन और चिकित्सा देखभाल प्रदान करना।
    </p>
    <p  className=' text-start' style={{lineHeight:"30px"}}>
    <b>आध्यात्मिक विकास: </b>आध्यात्मिक शिविरों, गीता प्रचार गतिविधियों, और भगवद गीता और शिवपुराण जैसे पवित्र ग्रंथों पर प्रवचन आयोजित करना।
    </p>
    
<p  className=' text-start' style={{lineHeight:"30px"}}><b>स्वास्थ्य और कल्याण: </b>नेत्र शिविर जैसे नि:शुल्क स्वास्थ्य शिविरों का आयोजन करना, जो आंखों से संबंधित बीमारियों का मुफ्त उपचार प्रदान करते हैं, और अन्य धर्मार्थ गतिविधियाँ करना।</p>
<p  className=' text-start' style={{lineHeight:"30px"}}><b>समुदाय की सहभागिता: </b>भक्तों और स्थानीय समुदाय को सेवा, भक्ति, और आध्यात्मिक शिक्षा में भाग लेने के लिए प्रेरित करना।</p>

<p  className=' text-start' style={{lineHeight:"30px"}}>हमारी प्रेरणा हमारे पूजनीय गुरुजी की आध्यात्मिक मार्गदर्शिता और श्री गोमुख महाराज से उनकी गहरी कनेक्शन से मिलती है, जिनकी आशीर्वाद हमारी राह को मार्गदर्शित करता है। साथ मिलकर, हम एक करुणामयी और आध्यात्मिक रूप से समृद्ध विश्व की दिशा में प्रयासरत हैं, जहाँ दूसरों की सेवा सबसे उच्च रूप की पूजा मानी जाती है।</p>
        </div>    

         <div className='col-md-6 col-12  d-flex justify-content-center flex-column align-items-center'>
            <img alt="mission image" src='Assets/imgs/temp/1 (18).png' width="100%" style={{borderRadius:"5px"}} ></img>
        </div>     
      </div>
    </div>
  )
}

export default AboutMission
