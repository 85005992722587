import React, { useState } from 'react';
import { BsStopwatch } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";
import { eventdata } from '../Utils/eventdata';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel CSS

const EventCompleted = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  // Function to open modal with selected event
  const openModal = (event) => {
    setSelectedEvent(event);
    setIsOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsOpen(false);
    setSelectedEvent(null);
  };
    return (
        <>
        <div className='container'>
          <div className='row'>
        
<h2 className='title text-center pb-3 pt-100'>पूर्ण कार्यक्रम</h2>
          {eventdata
           .filter((data) => data.category === 'completed')
           .map((data , ind)=>{
            return (
              <div className='col-md-4 col-12 mb-4' key={ind}>
                <div className="card" onClick={() => openModal(data)} style={{ cursor: 'pointer' }}>
                  <div><span className="badge badge-secondary" style={{ padding: "10px", marginBottom: "-40px", background: "#ff7b00" }}>
                    {data.category}</span></div>
                  <img className="card-img-top" style={{ marginTop: "-40px" }}
                    src="/Assets/imgs/temp/Untitled design (80).png" alt="Card cap" />
                  <div className="card-body">
                    <h5 className="card-title">{data.title}</h5>
                    <p className="card-text">{data.description}</p>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item"><SlCalender style={{ marginRight: "10px" }} /> {data.date}</li>
                    <li className="list-group-item"><BsStopwatch style={{ marginRight: "10px" }} /> {data.time}</li>
                    <li className="list-group-item"><IoLocationOutline style={{ marginRight: "10px" }} />  {data.address}</li>
                  </ul>
                  <div className="card-body">
                    <a onClick={() => openModal(data)} className="btn-primary card-link" style={{ fontWeight: "800", color: "#ff7b00" }}>Know More</a>
                  </div>
                </div>
              </div>
            );
          })}
        
          </div>
        </div>
        
        
      {/* Modal for displaying event details */}
      {isOpen && selectedEvent && (
        <div className="modal " onClick={closeModal} style={{ paddingTop: "600px" }}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <span className="close " onClick={closeModal}>&times;</span>

            {/* Carousel for images */}
            <Carousel className='pt-4'
              showArrows={true}
              infiniteLoop={true}
              useKeyboardArrows={true}
              swipeable={true}
              emulateTouch={true}
              renderIndicator={(onClickHandler, isSelected, index, label) => (
                <li
                  style={{
                    background: isSelected ? '#000' : '#ccc',
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    display: 'inline-block',
                    margin: '0 8px',
                    cursor: 'pointer'
                  }}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  role="button"
                  aria-label={`Select ${label} slide`}
                  aria-selected={isSelected}
                />
              )}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button type="button" onClick={onClickHandler} title={label} className="arrow-prev">
                    ‹
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button type="button" onClick={onClickHandler} title={label} className="arrow-next">
                    ›
                  </button>
                )
              }
            >
              {/* <div>
                <img src="/Assets/imgs/temp/1 (2).png" alt="Event 1" />
              </div>
              <div>
                <img src="/Assets/imgs/temp/1 (2).png" alt="Event 2" />
              </div>
              <div>
                <img src="/Assets/imgs/temp/1 (2).png" alt="Event 3" />
              </div> */}
{selectedEvent.allimg.map((image, index) => (
        <div key={index}>
          <img src={image.src} alt={image.alt} />
        </div>
      ))}
            </Carousel>

            <h2>{selectedEvent.title}</h2>
            <p style={{lineHeight:"25px"}}>{selectedEvent.fulldescription}</p>
            <ul style={{marginBottom:"90px" ,lineHeight:"25px"}}>
              <li><SlCalender style={{ marginRight: "10px" }} /> {selectedEvent.date}</li>
              <li><BsStopwatch style={{ marginRight: "10px" }} /> {selectedEvent.time}</li>
              <li><IoLocationOutline style={{ marginRight: "10px" }} /> {selectedEvent.address}</li>
            </ul>
          </div>
        </div>
      )}

      <style jsx>{`
        .modal {
          display: flex;
          position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          align-items: center;
          justify-content: center;
        }
        .modal-content {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          max-width: 600px;
          width: 100%;
        }
        .close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: #000;
          font-size: 30px;
          cursor: pointer;
        }
        .arrow-next, .arrow-prev {
          background-color: black;
          color: white;
          border: none;
          padding: 10px;
          font-size: 30px;
          cursor: pointer;
          z-index: 1;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        .arrow-prev {
          left: 15px;
        }
        .arrow-next {
          right: 15px;
        }
      `}</style>
        
        
        </>  )
}

export default EventCompleted