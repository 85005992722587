import React from 'react'

const AboutInfo = () => {
  return (
    <>
    <div className='container pt-100 '>
    <h2 className='text-center '>हमारे बारे में</h2>
    <p className='custom-p'>गुरुकृपा गो सेवा धाम एक आध्यात्मिक धाम है, जो गायों की सेवा और हमारे पूजनीय गुरुजी की शिक्षाओं को समर्पित है। धोलेरा के अक्रूर मंदिर के पास स्थित यह पवित्र स्थान 2022 में स्थापित किया गया था, जहाँ गुरुजी अपनी गायों के साथ रहकर सेवा, करुणा और निस्वार्थता के आदर्शों को जीवित रखते हैं। यहाँ आने वाले भक्तों और समाज के लोगों को न केवल आध्यात्मिक ज्ञान प्राप्त होता है, बल्कि सेवा और दान के माध्यम से मानवता की भलाई के लिए कार्य करने का अवसर भी मिलता है।</p>
    <p className='custom-p'>2023 में भक्तों के सहयोग से, हमने दूसरे तल और गोशाला का निर्माण किया, जहाँ गायों की देखभाल और संरक्षण किया जाता है। हमारी गतिविधियाँ गुरुजी की शिक्षाओं पर आधारित हैं, जो गीता और शिवपुराण की गहरी समझ और प्रेरणा से निर्देशित हैं। गुरुकृपा गो सेवा धाम का उद्देश्य आध्यात्मिक जागरूकता फैलाना, समाज की सेवा करना और धर्म के मूल्यों को लोगों तक पहुँचाना है।</p>
    <p className='custom-p'>हम गोशाला में गायों की देखभाल के साथ-साथ विभिन्न आध्यात्मिक शिविरों, स्वास्थ्य शिविरों और धार्मिक कार्यों का आयोजन करते हैं, जिसमें नेत्र शिविर और भगवद गीता के प्रचार का विशेष स्थान है।</p>
    </div>
<div className='container-fluid m-0 p-0 '>
{/* <img width="100% " src='Assets/imgs/temp/cow.jpeg' height="200px" alt="About image"></img> */}
</div>
    </> 
  )
}

export default AboutInfo
