
export const Allimages = [
    'Assets/imgs/gallery/1.png',
    'Assets/imgs/gallery/2.png',
    'Assets/imgs/gallery/3.png',
    'Assets/imgs/gallery/4.png',
    'Assets/imgs/gallery/5.png',
    'Assets/imgs/gallery/6.png',
    'Assets/imgs/gallery/7.png',
    'Assets/imgs/gallery/8.png',
    'Assets/imgs/gallery/9.png',
    'Assets/imgs/gallery/10.png',
    'Assets/imgs/gallery/11.png',
    'Assets/imgs/gallery/santseva/1.png',
    'Assets/imgs/gallery/santseva/2.png',
    'Assets/imgs/gallery/santseva/3.png',
    'Assets/imgs/gallery/santseva/4.png',
    'Assets/imgs/gallery/santseva/5.png',
    'Assets/imgs/gallery/santseva/6.png',
    'Assets/imgs/gallery/santseva/7.png',
   'Assets/imgs/gallery/chardham/1.png',
    'Assets/imgs/gallery/chardham/2.png',
    'Assets/imgs/gallery/chardham/3.png',
    'Assets/imgs/gallery/chardham/4.png',
    'Assets/imgs/gallery/chardham/5.png',
    'Assets/imgs/gallery/cowgallery/1.png',
    'Assets/imgs/gallery/cowgallery/2.png',
    'Assets/imgs/gallery/cowgallery/3.png',
    'Assets/imgs/gallery/cowgallery/4.png',
    'Assets/imgs/gallery/cowgallery/5.png',
    'Assets/imgs/gallery/cowgallery/6.png',
    'Assets/imgs/gallery/netra/1.png',
    'Assets/imgs/gallery/netra/2.png',
    'Assets/imgs/gallery/netra/3.png',
    'Assets/imgs/gallery/netra/4.png',
    'Assets/imgs/gallery/netra/5.png',
    'Assets/imgs/gallery/netra/6.png',
    'Assets/imgs/gallery/netra/7.png',
    'Assets/imgs/gallery/netra/1.png',
    'Assets/imgs/gallery/netra/2.png',
    'Assets/imgs/gallery/netra/3.png',
    'Assets/imgs/gallery/netra/4.png',
    'Assets/imgs/gallery/netra/5.png',
    'Assets/imgs/gallery/netra/6.png',
    'Assets/imgs/gallery/netra/7.png',
  ];

  