import React from 'react'
import Nav from '../ComponentHeaderFooter/Nav'
import PreHeader from '../ComponentHeaderFooter/PreHeader'
import Temp from '../ComponentHeaderFooter/Temp'
import Testimonial from '../ComponentHeaderFooter/Testimonial'
import Testi from '../ComponentHeaderFooter/Testi'

const Header = () => {
  return (
    <>
    <PreHeader/>
    <Nav/>
    </>
  )
}

export default Header