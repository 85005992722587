import React from "react";
import { Link } from "react-router-dom";

const WhatWeDo = () => {
  return (
    <div
      className="container-fluid mt-md-5 mt-3 pt-120 bg-image pb-120"
      style={{
        backgroundImage: `url('/Assets/imgs/temp/bg.png')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <h2 className="title text-center pb-3 ">हमारे कार्य</h2>
      <div className="row events-container justify-content-center">
     
        <div className="col-md-2 col-10 event-section m-md-0 p-md-0 px-md-2"  style={{cursor:"pointer"}}>
        <Link to='/cow-gallery'>
          <div className="event-img m-2 p-2 bg-light text-center event-box">
          
            <img
              src="Assets/imgs/home/2.png"
              width="100%"
              alt="image1"
              style={{ borderRadius: "5px" }}
            />
            <h6 className="pb-0 mb-0 pt-4">गाय संरक्षण और देखरेख</h6>
          </div>
          </Link>
        </div>

        <div className="col-md-2 col-10 event-section m-md-0 p-md-0 px-md-2" style={{cursor:"pointer"}}>
          <Link to='/char-dham-yatra'>
          <div className="event-img m-2 p-2 bg-light text-center event-box">
            <img
              src="Assets/imgs/whatwedo/5.png"
              width="100%"
              alt="image1"
              style={{ borderRadius: "5px" }}
            />
            <h6 className="pb-0 mb-0 pt-4">चार धाम तीर्थ स्थल</h6>
          </div>
          </Link>
        </div>

        <div className="col-md-2 col-10 event-section m-md-0 p-md-0 px-md-2" style={{cursor:"pointer"}}>
          <Link to='/bhagvadgita-prachar'>
          <div className="event-img m-2 p-2 bg-light text-center event-box">
            <img
              src="Assets/imgs/whatwedo/1.png"
              width="100%"
              alt="image1"
              style={{ borderRadius: "5px" }}
            />
            <h6 className="pb-0 mb-0 pt-4">संत सेवा </h6>
          </div>
          </Link>
        </div>

        <div className="col-md-2 col-10 event-section m-md-0 p-md-0 px-md-2" style={{cursor:"pointer"}}>
            <Link to='/health-checkup'>
          <div className="event-img m-2 p-2 bg-light text-center event-box">
            <img
              src="Assets/imgs/whatwedo/4.png"
              width="100%"
              alt="image1"
              style={{ borderRadius: "5px" }}
            />
            <h6 className="pb-0 mb-0 pt-4">स्वास्थ्य शिविर </h6>
          </div>
          </Link>
        </div>

        <div className="col-md-2 col-10 event-section m-md-0 p-md-0 px-md-2" style={{cursor:"pointer"}}>
          <Link to='/kanya-vivah'>
          <div className="event-img m-2 p-2 bg-light text-center event-box">
            <img
              src="Assets/imgs/whatwedo/3.png"
              width="100%"
              alt="image1"
              style={{ borderRadius: "5px" }}
            />
            <h6 className="pb-0 mb-0 pt-4">अनाथ कन्या विवाह</h6>
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
