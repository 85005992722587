// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// const ScrollToTop1 = () => {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]); // Run effect every time the route changes

//   return null; // This component doesn't render anything
// };

// export default ScrollToTop1;
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop1 = () => {
  const ref = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [pathname]); // Run effect every time the route changes

  return <div ref={ref} />; // This div is the scroll target
};

export default ScrollToTop1;
