import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Service from './pages/Service';
import Event from './pages/Event';
import Donation from './pages/Donation';
import Header from './HeaderFooter/Header';
import Footer from './HeaderFooter/Footer'
import Gallery from './pages/Gallery';
import EventUpcomming from './Components/EventUpcomming';
import EventCompleted from './Components/EventCompleted';
import CowGallery from './Gallery/CowGallery';
import CharDham from './Gallery/CharDham';
import Bhagvadgita from './Gallery/Bhagvadgita';
import HealthCheckup from './Gallery/HealthCheckup';
import Kanyavivaah from './Gallery/Kanyavivaah';
import ScrollToTop from 'react-scroll-to-top';
import ScrollToTop1 from './Scroll-to-top';
const App = () => {
  return (
    <BrowserRouter>
    <AppContent/>
    </BrowserRouter>
  )
}

function AppContent() {
  return (
    <>
      <ScrollToTop1 />
    <Header/>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route exact path="/about" element={<About/>} />
      <Route exact path="/contact" element={<Contact/>} />
      <Route exact path="/donation" element={<Donation/>} />
      <Route exact path="/service" element={<Service/>} />
      <Route exact path="/event" element={<Event/>} />
      <Route exact path="/event/upcomming" element={<EventUpcomming/>} />
      <Route exact path="/event/completed" element={<EventCompleted/>} />  
      <Route exact path='/gallery' element={<Gallery/>} />
      <Route exact path="/cow-gallery" element={<CowGallery/>} />
      <Route exact path="/char-dham-yatra" element={<CharDham/>} />
      <Route exact path="/bhagvadgita-prachar" element={<Bhagvadgita/>} />  
      <Route exact path='/health-checkup' element={<HealthCheckup/>} />
      <Route exact path='/kanya-vivah' element={<Kanyavivaah/>} />
    </Routes> 
    <ScrollToTop smooth color='red' />
    <Footer/>
    </>
    )
}


export default App