import React from 'react'
import AboutInfo from '../Components/AboutInfo'
import WhatWeDo from '../ComponentHeaderFooter/WhatWeDo'
import Journey from '../ComponentHeaderFooter/Journey'
import Mission from '../ComponentHeaderFooter/Mission'
import History from '../ComponentHeaderFooter/History'
import AboutHero from '../Components/AboutHero'
import AboutMission from '../ComponentHeaderFooter/AboutMission'

const About = () => {
  return (
  <>
    <AboutHero/>
    <AboutInfo/>
    <WhatWeDo/>
    {/* परियोजनाएँ और गतिविधियाँ */}
    <History/>
    {/* <Journey/> */}
    <AboutMission/>
  </> 
  )
}
// order-lg-first
// order-lg-end

export default About