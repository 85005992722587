import React from 'react'
import ContactInfo from '../ComponentHeaderFooter/ContactInfo'
import Form from '../Components/Form'

const Contact = () => {
  return (<>
    <ContactInfo/>
        <Form/>
    </>
  )
}

export default Contact