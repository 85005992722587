import React from 'react'
import { Link } from 'react-router-dom'

const DonationHero = () => {
  return (
    <div className='container-fluid '  style={{backgroundImage: `url('/Assets/imgs/temp/Untitled design (77).png')`, backgroundPosition:"center"}}  >
        <div className='row py-md-5 py-3'>
            <div className='col-md-7 col-12 m-0 p-0 mb-md-0 imgdiv d-flex ps-lg-5 flex-column justify-content-center  align-items-center '>
              <img src='/Assets/imgs/temp/1 (1).png' width="100%" className='d-flex ps-lg-5 flex-column justify-content-center align-items-center ' />            
            </div>
            <div className='col-md-5 col-12 d-flex ps-lg-5 flex-column justify-content-center align-items-lg-start align-items-center imgContent'>
                <h2 className='pb-4'>गुरु गौ सेवा ट्रस्ट
                में दान <br/>कैसे करें? </h2>
                {/* <Link to='/donation'  type="button" class="btn btn-primary text-white common-btn m-1">दान करें</Link> */}
              </div>
        </div>


        <div className='row p b-5'>
        <h2 className='text-center  d-flex flex-column justify-content-center align-item-center'>बैंक विवरण</h2>
        <h3 className='text-center'><b>स्टेट बैंक ऑफ इंडिया वृंदावन</b></h3>
       
        <div  className='text-center  d-flex justify-content-center align-item-center mb-4'>
        <img src='/Assets/imgs/temp/sbi.png' className=''/>
        </div>
        <h4 className='text-center'><b>अकाउंट नंबर : 41018192710</b></h4>
        <h4 className='text-center'><b>आई एफ एस कोड : SBIN0016533</b></h4>
        </div>
    </div>
  )
}


export default DonationHero