
export const netra = [
    'Assets/imgs/gallery/netra/1.png',
    'Assets/imgs/gallery/netra/2.png',
    'Assets/imgs/gallery/netra/3.png',
    'Assets/imgs/gallery/netra/4.png',
    'Assets/imgs/gallery/netra/5.png',
    'Assets/imgs/gallery/netra/6.png',
    'Assets/imgs/gallery/netra/7.png',
    
  ];

  