import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";

const ContactInfo = () => {
  return (
    <>
        <div className='container' >
            <div className='row d-flex flex-row justify-content-center align-item-center'>
            <div className='col-lg-7 col-12 d-flex flex-row justify-content-center align-item-center'>
                <img src='Assets/imgs/home/5.jpg' width="100%"   alt="Contact Image"/>
            </div>
            <div className='col-lg-5 col-12 d-flex flex-column justify-content-center align-item-center ps-lg-5 '>
                <h2 className='contactinfoHead text-lg-start text-center pt-lg-0 pt-5'>हमसे संपर्क करें</h2>
                <a className='contactinfo text-lg-start text-center p-2 ps-0' href='https://wa.link/9hqphq' 
                style={{fontWeight:"bold"}}
                >
                <FaWhatsapp    style={{marginRight:"10px"}}/> +917017421499</a>
                <a className='contactinfo text-lg-start text-center p-2 ps-0' href='tel:917017421499'  style={{fontWeight:"bold"}}
                ><IoMdCall style={{marginRight:"10px"}} /> +917017421499</a>
                <a className='contactinfo text-lg-start text-center p-2 ps-0' href='mailto:info@ggskdham.com'  style={{fontWeight:"bold"}}
                ><FaEnvelope style={{marginRight:"10px"}}/> info@ggskdham.com</a>
            </div>
            </div>

            {/* 2nd Section start */}
        <div className='row pt-100 pb-100'>
        <div className='col-md-5 col-12 d-flex flex-column 
        justify-content-center align-item-center  order-2 order-md-1'>
            <h3 className='text-lg-start text-center mt-lg-0 mt-5'>गुरु सेवा कृपा धाम</h3>
            <h5 className='text-lg-start text-center'>मुख्य कार्यालय</h5>
            <p className='text-lg-start text-center'><b>आश्रम का  पता :</b>
              </p>
              <p className='text-lg-start text-center'>गुरु गौ सेवा धाम, आनंद वाटिका के पास , परिक्रमा मार्ग, वृंदावन 281121
              </p>
              <p className='text-lg-start text-center'><b>गुरु गौ सेवा ट्रस्ट का  पता :</b>
              </p>
              <p className='text-lg-start text-center'>गुरु गौ सेवा धाम , गौ शाला, अक्रूर मंदिर के पास, धोलेरा वृंदावन
              </p>
            <h5 className='text-lg-start text-center'>फोन नंबर</h5>
            <p className='text-lg-start text-center'> +91 7017421499</p>
            <p className='text-lg-start text-center'><FaWhatsapp/> +91 7017421499</p>

        </div>
        <div className='col-md-7 col-12 order-1 order-md-2 '>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14147.464425023618!2d77.66765333555423!3d27.56666560537839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39736f75ea423989%3A0x37d16207dc56bf2c!2sAnand%20Vatika!5e0!3m2!1sen!2sin!4v1728026307123!5m2!1sen!2sin" width="100%" height="500" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        </div>

        </div>
    </>
)
}

export default ContactInfo
