import React from 'react'

const AboutUs = () => {
  return (
    <div className='container pt-100 pb-5' >
      <div className='row'>
        
        <div className='col-md-5 col-12 d-flex justify-content-center flex-column align-items-lg-start'>
    <h2 className=' mt-lg-0 mt-5 text-lg-start text-center'>हमारे बारे में</h2>
    <p className='custom-p'>स्वामी गुरु कृपानंद जी महाराज एक प्रसिद्ध संत और समाजसेवी हैं, जिन्होंने अपने जीवन को मानवता की सेवा और धर्म के प्रचार के लिए समर्पित किया है। उनका प्रमुख उद्देश्य भगवद गीता के संदेश को जन-जन तक पहुँचाना और समाज को नैतिक और आध्यात्मिक रूप से सशक्त बनाना है। स्वामी जी ने धर्म का प्रचार केवल उपदेशों तक सीमित नहीं रखा, बल्कि गौ सेवा, संत सेवा, और कन्या विवाह जैसे महत्वपूर्ण कार्यों में भी अपनी प्रमुख भूमिका निभाई है।  </p>
    <p className='custom-p'>उनके द्वारा चलाए जा रहे सामाजिक और धार्मिक कार्यों के माध्यम से, समाज के हर वर्ग को सहयोग और प्रेरणा मिलती है। स्वामी गुरु कृपानंद जी महाराज के नेतृत्व में, हजारों लोग धर्म और सेवा के पवित्र मार्ग पर अग्रसर हैं। </p>
        </div>       

        <div className='col-md-7 col-12'>

        <video width="100%" height="auto" controls autoPlay muted loop>
        <source src="Assets/imgs/temp/3.mp4" type="video/mp4" />  
        </video>

            {/* <img alt="mission image" src='Assets/imgs/temp/3.mp4' width="100%" style={{borderRadius:"5px"}} ></img> */}
        </div>

      </div>
    </div>
  )
}

export default AboutUs

// import React from 'react'

// const AboutUs = () => {
//   return (
//     <div className='container pt-100 pb-5' >
//       <div className='row'>
//         <div className='col-md-6 col-12'>
//             <img alt="mission image" src='Assets/imgs/temp/1 (2).png' width="100%" style={{borderRadius:"5px"}} ></img>
//         </div>
//         <div className='col-md-6 col-12 d-flex justify-content-center flex-column align-items-lg-start'>
//     <h2 className=' mt-lg-0 mt-5 text-lg-start text-center'>हमारे बारे में</h2>
//     <p className='custom-p'>मंगल दर्शन :– गुरु गौ सेना कृपा धाम' यह सुन्दर स्थान श्री धाम वृन्दावन में परिक्रमा मार्ग पर गौमाता की सेवा एवं उजला महावीर नाम सें प्रगट स्वयंभू हनु‌मान जी के लिए गसद्ध है। इसी स्थान पर वृन्देश्वर महादेव, श्याम-श्यामाजूव श्री सीताराम युगल सरकार बिवाजमान है। 
//     </p>
//     <p className='custom-p'>स्वयंभू हनुमान जी गौमुख वाले ब्रहमचारीबाबा जिनकी आयु 120 वर्ष उमाह है। उन्ही के कर कमलों से परम पूज्य स्वामी गुरुकृपानन्द जी महाराज को स्वयंभू हनुमान जी (उजला महावीर) भेंट स्वरना प्रदान किये गये। जो कि परमपूज्य महाराज जी के आध्यात्मिक गुरु भी है स्वयंभू हनुमान जी मनोकामना पूर्ति के साथ साथ आत्मोत्यान व आत्मकल्याण की प्रेरणा के अद्‌भुत स्रोत है।
//     </p>
//         </div>        
//       </div>
//     </div>
//   )
// }

// export default AboutUs
