import React from 'react'
import { Link } from 'react-router-dom'

const AboutHero = () => {
  return (
    <div className='container-fluid '  style={{backgroundImage: `url('/Assets/imgs/temp/Untitled design (79).png')`, backgroundPosition:"center"}}  >
        <div className='row py-5'>
            <div className='col-md-7 col-12 m-0 p-0 mb-md-0 imgdiv d-flex ps-lg-5 flex-column justify-content-center  align-items-center '>
              <img src='/Assets/imgs/temp/1 (1).png' width="100%" className='d-flex ps-lg-5 flex-column justify-content-center align-items-center ' />            
            </div>
            <div className='col-md-5 col-12 d-flex ps-lg-5 flex-column justify-content-center align-items-lg-start align-items-center imgContent'>
            <h4 className='pb-2'>आपका और हमारा प्रयास ला सकता है</h4>
                <h2 className='pb-4'>किसी के जीवन में सकारात्मक बदलाव।'</h2>
                <Link to='/donation'  type="button" class="btn btn-primary text-white common-btn m-1">दान करें</Link>
            
                {/* <button className='btn btn-primary rounded-2 common-btn '> Donate </button> */}
            </div>
        </div>
    </div>
  )
}




export default AboutHero