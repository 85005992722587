import React from "react";
import { Link } from "react-router-dom";

const History = () => {
  return (
    <>
      {/*=================== service area five start ===================*/}
      <div className='service-area bg-gray bg-relative pd-120 pd-120 pt-100' style={{backgroundColor:"#FFFFEB"}}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h2 className='title'>
                परियोजनाएँ और गतिविधियाँ
                </h2>
                <p className="text-center">हमारी परियोजनाएँ विभिन्न प्रकार की हैं, जो समुदाय की शारीरिक और आध्यात्मिक आवश्यकताओं पर ध्यान केंद्रित करती हैं:</p>
              </div>
            </div>
          </div>
          <div className='row custom-no-gutter'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-3 single-service-inner-3-left'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='Assets/imgs/home/cow.png' width="50px" height="50px" alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>गोशाला (गाय आश्रय):</Link>
                  </h5>
                  <p className='mb-0'>
                  जनवरी 2024 में भक्तों के सहयोग से निर्मित हमारी गोशाला में कई गायों का आश्रय है। यहाँ इन कोमल जीवों की प्रेम और सम्मान के साथ देखभाल की जाती है।
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-left'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='Assets/imgs/icon/eye.png' width="50px" height="50px" alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>नेत्र शिविर:</Link>
                  </h5>
                  <p className='mb-0'>
                  यह नि:शुल्क चिकित्सा शिविर भक्तिवेदांत अक्षयपात्र अस्पताल के सहयोग से आयोजित किया जाता है, जिसमें मोतियाबिंद जैसी आवश्यक नेत्र चिकित्सा सेवाएँ प्रदान की जाती हैं। शिविर का आयोजन नवंबर 2023 में किया गया था और यह स्थानीय समुदाय की सेवा करता है।
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-left mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='Assets/imgs/icon/bhagavad-gita.png' width="50px" height="50px" alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>गीता प्रचार: </Link>
                  </h5>
                  <p className='mb-0'>
                  गीता मालयश्री महाराज के मार्गदर्शन में हमने अंबाला, वृंदावन, कुरुक्षेत्र और दिल्ली में भगवद गीता की शिक्षाओं को बढ़ावा देने के लिए कई पहल शुरू की हैं। ये कार्यक्रम अब अंतरराष्ट्रीय स्तर पर भी विस्तारित हो चुके हैं।
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 bg-blue-right d-lg-inline-block d-none'>
              <div className='service-thumb service-middle-section align-self-end'>
                <img src='Assets/imgs/temp/1 (23).png' alt='img' height="750px" width="300px" />
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-3 single-service-inner-3-right'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='Assets/imgs/icon/meditation.png' width="50px" height="50px" alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>आध्यात्मिक प्रवचन:</Link>
                  </h5>
                  <p className='mb-0'>
                  गुरुजी के द्वारा नियमित रूप से गीता और शिवपुराण पर आध्यात्मिक प्रवचन आयोजित किए जाते हैं, जिससे भक्तों को भक्ति और आत्म-साक्षात्कार के मार्ग पर चलने की प्रेरणा मिलती है।
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-right'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='Assets/imgs/icon/lotus.png' width="50px" height="50px" alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>योग और ध्यान शिविर:  </Link>
                  </h5>
                  <p className='mb-0'>
                  नियमित योग और ध्यान शिविरों के माध्यम से हम लोगों को मानसिक और शारीरिक स्वास्थ्य को बेहतर बनाने के लिए प्रेरित करते हैं। ये शिविर हर महीने आयोजित किए जाते हैं और समुदाय के सभी वर्गों के लिए खुले होते हैं।  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-right mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='Assets/imgs/icon/better-health.png' width="50px" height="50px" alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>स्वास्थ्य सेवा: </Link>
                  </h5>
                  <p className='mb-0'>
                  गुरुकृपा गो सेवा धाम में नि:शुल्क स्वास्थ्य शिविरों का आयोजन किया जाता है, जिसमें गरीब और ज़रूरतमंद लोगों को चिकित्सा सेवाएँ प्रदान की जाती हैं। हमारा उद्देश्य स्थानीय समुदाय के स्वास्थ्य को सुधारना है।  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default History;
