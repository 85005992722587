import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { RiMenu5Fill } from "react-icons/ri";

const Nav = () => {
    const [active, setActive] = useState(false);
    const menuActive = () => {
      setActive(!active);
    };
   
    // Control sidebar navigation
    let items = document.querySelectorAll(".menu-item-has-children > a");
    for (let i in items) {
      if (items.hasOwnProperty(i)) {
        items[i].onclick = function () {
          this.parentElement
            .querySelector(".sub-menu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
  
    return (
      <>
        {/* navbar start */}
        <nav className=' navbar navbar-area navbar-expand-lg '>
          <div className='container-fluid m-0  nav-container navbar-bg'>
            <div className='responsive-mobile-menu'>
              <button
                onClick={menuActive} 
                className={
                  active
                    ? "menu toggle-btn d-block d-lg-none open"
                    : "menu toggle-btn d-block d-lg-none"
                }
                data-target='#itech_main_menu'
                aria-expanded='false'
                aria-label='Toggle navigation'
              ><RiMenu5Fill/>
                <span className='icon-left' />
                <span className='icon-right' />
              </button>
            </div>
            <div className='logo'>
              <Link to='/'>
                <img src='Assets/imgs/home/logo (1).png' height="100px"  alt='img' />
              </Link>
            </div>
            <div
              className={
                active
                  ? "collapse navbar-collapse sopen"
                  : "collapse navbar-collapse"
              }
              id='itech_main_menu'
            > 
            <ul className='navbar-nav menu-open text-lg-end'>
              <li><Link to='/'>होम</Link> </li>
              <li><Link to='/about'>हमारे बारे में</Link></li>
              <li className='menu-item-has-children'>
                  <Link to='/event'> कार्यक्रम
                  <IoIosArrowDown /></Link>
                  <ul className='sub-menu'>          
                    <li>
                      <Link to='/event/upcomming'>आगामी कार्यक्रम</Link>
                    </li>
                    <li>
                      <Link to='/event/completed'>पूर्ण कार्यक्रम</Link>
                    </li>
                  
                   </ul>
                </li>
                <li><Link to='/gallery'>फोटो गैलरी</Link></li>
                <li><Link to='/contact'>हमसे जुड़े रहें</Link></li>
              </ul>
            </div>
            <div className='nav-right-part nav-right-part-desktop align-self-center'>
              {/* <a href='tel:+919109829396' type="button" class="btn btn-primary m-1 text-white">Call Now</a> */}
              <Link to='/donation'  type="button" class="btn btn-primary text-white common-btn m-1">दान करें</Link>
            </div>
          </div>
        </nav>
        {/* navbar end */}
      </>
    );
  };
  

export default Nav

