import React from 'react'
import EventAll from '../Components/EventAll'

const Event = () => {
  return (
<>
  <EventAll/>
</>
  )
}

export default Event