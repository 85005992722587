import React from 'react'

const Hanumanji = () => {
  return (
    <div className='container pt-100 pb-5' >
      <div className='row'>
        <div className='col-md-6 col-12'>
            <img alt="mission image" src='Assets/imgs/temp/hanumanji.png' width="100%" style={{borderRadius:"5px"}} ></img>
        </div>
        <div className='col-md-6 col-12 d-flex justify-content-center flex-column align-items-lg-start'>
    <h2 className=' mt-lg-0 mt-5 text-lg-start text-center'>कवन सो काज कठिन जग माहीं, जो नहिं होइ तात तुम पाहीं।</h2>
    <p className='custom-p'>स्वयंभू हनुमानजी की प्राण प्रतिष्ठा कार्यक्रम पूज्य गुरुदेव भगवान श्री ज्ञानानंद जी महाराज एवम पूज्य गुरुदेव श्री कृपानंद जी महाराज के कर कमलों द्वारा 17 जुलाई 2024 को किया गया   </p>
    <p className='custom-p'>स्वयंभू हनुमान जी गौमुख वाले ब्रहमचारीबाबा जिनकी आयु 120 वर्ष 3 माह है। उन्ही के कर कमलों से परम पूज्य स्वामी गुरुकृपानन्द जी महाराज को स्वयंभू हनुमान जी (उजला महावीर) भेंट स्वरना प्रदान किये गये। जो कि परमपूज्य महाराज जी के आध्यात्मिक गुरु भी है स्वयंभू हनुमान जी मनोकामना पूर्ति के साथ साथ आत्मोत्यान व आत्मकल्याण की प्रेरणा के अद्‌भुत स्रोत है।
    </p>
        </div>        
      </div>
    </div>
  )
}


export default Hanumanji