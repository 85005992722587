import React from 'react'
import AllGallery from '../Components/AllGallery'

const Gallery = () => {
  return (
    <>
      <AllGallery/>
    </>
  )
}

export default Gallery