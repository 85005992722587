import React, { useState } from 'react';
import {Allimages} from '../Utils/Allimages'

const AllGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  // const images = [
  //   'Assets/imgs/gallery/1.png',
  //   'Assets/imgs/gallery/2.png',
  //   'Assets/imgs/gallery/3.png',
  //   'Assets/imgs/gallery/4.png',
  //   'Assets/imgs/gallery/5.png',
  //   'Assets/imgs/gallery/6.png',
  //   'Assets/imgs/gallery/7.png',
  //   'Assets/imgs/gallery/8.png',
  //   'Assets/imgs/gallery/9.png',
  //   'Assets/imgs/gallery/10.png',
  //   'Assets/imgs/gallery/11.png',
  // ];

  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImage('');
  };

  return (
    <>
      <div className='container'>
        <div className="container pb-4 pt-100">
          <div className='section-title text-center'>
            <h2 className='title text-center'>फोटो गैलरी</h2>
          </div>
        </div>

        <div className='row'>
          {Allimages.map((image, index) => (
            <div className='col-md-4 col-12 my-3' key={index}>
              <img
                src={image}
                className='thumbimg'
                alt={`Gallery image ${index + 1}`}
                onClick={() => openModal(image)}
                style={{ cursor: 'pointer' }} // Change cursor to pointer for better UX
              />
            </div>
          ))}
        </div>

        {/* Modal for displaying the clicked image */}
        {isOpen && (
          <div className='modal' onClick={closeModal}>
            <div className='modal-frame'>
              <span className='close' onClick={closeModal}>&times;</span>
              <img className='modal-content' src={selectedImage} alt='Selected' />
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        .modal {
          display: flex;
          position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.9);
          align-items: center;
          justify-content: center;
        }
        .modal-frame {
          width: 100%;
          max-width: 90%;
          margin: auto;
          background-color: #fff;
          padding: 20px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .modal-content {
          max-width: 100%;
          max-height: 80vh;
          border-radius: 10px;
        }
        .close {
          position: absolute;
          top: 20px;
          right: 30px;
          color: #ffffff;
          font-size: 40px;
          cursor: pointer;
        }
        
        @media (min-width: 992px) {
          .modal-frame {
            width: 30%; /* Show half the screen width on large devices */
          }
        }

        @media (max-width: 991px) {
          .modal-frame {
            width: 100%; /* Full width on smaller screens */
          }
        }
      `}</style>
    </>
  );
};

export default AllGallery;
