import React, { useState } from 'react';
import {Cowgallery} from '../Utils/Cowgallery'

const CowGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImage('');
  };

  return (
    <>
      <div className='container pb-5'>
        <div className="container pb-4 pt-100">
          <div className='section-title text-center'>
            <h2 className='title text-center'>फोटो गैलरी</h2>
            <p className='custom-p text-center'>
            स्वामी गुरु कृपानंद जी महाराज गौ सेवा को अपना धार्मिक कर्तव्य मानते हैं। उनका विश्वास है कि गायें पृथ्वी की माता के रूप में हमें पोषण और शांति प्रदान करती हैं। गुरु जी गायों के रक्षण और उनके अधिकारों के लिए काम करते हैं, और समाज को भी गौ सेवा के लिए प्रेरित करते हैं। उनके अनुयायी भी इस पवित्र कार्य में अपना योगदान देते हैं, ताकि धरती पर संतुलन बना रहे।   </p>
          </div>
        </div>

        <div className='row'>
          {Cowgallery.map((image, index) => (
            <div className='col-md-4 col-12 my-3' key={index}>
              <img
                src={image}
                className='thumbimg'
                alt={`Gallery image ${index + 1}`}
                onClick={() => openModal(image)}
                style={{ cursor: 'pointer' }} // Change cursor to pointer for better UX
              />
            </div>
          ))}
        </div>

        {/* Modal for displaying the clicked image */}
        {isOpen && (
          <div className='modal' onClick={closeModal}>
            <div className='modal-frame'>
              <span className='close' onClick={closeModal}>&times;</span>
              <img className='modal-content' src={selectedImage} alt='Selected' />
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        .modal {
          display: flex;
          position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.9);
          align-items: center;
          justify-content: center;
        }
        .modal-frame {
          width: 100%;
          max-width: 90%;
          margin: auto;
          background-color: #fff;
          padding: 20px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .modal-content {
          max-width: 100%;
          max-height: 80vh;
          border-radius: 10px;
        }
        .close {
          position: absolute;
          top: 20px;
          right: 30px;
          color: #ffffff;
          font-size: 40px;
          cursor: pointer;
        }
        
        @media (min-width: 992px) {
          .modal-frame {
            width: 30%; /* Show half the screen width on large devices */
          }
        }

        @media (max-width: 991px) {
          .modal-frame {
            width: 100%; /* Full width on smaller screens */
          }
        }
      `}</style>
    </>
  );
};


export default CowGallery