import React from 'react'
import { Link } from 'react-router-dom'

const HomeHero = () => {
  return (
    <div className='container-fluid common-padding py-4' 
    style={{backgroundImage: `url('/Assets/imgs/temp/bg.png')`, backgroundPosition:"center"}} >
        <div className='row'>
            <div className='col-md-7 col-12 m-0 p-0 mb-md-0 imgdiv'>
              {/* <img src='/Assets/imgs/temp/sucess-story-banner.jpg' width="100%" />     */}
              <video width="100%" height="auto" controls autoPlay muted>
        <source src="Assets/imgs/temp/1 (9).mp4" type="video/mp4" />  
        </video>
        </div>
         
            <div className='col-md-5 col-12 d-flex ps-lg-5 flex-column justify-content-center align-items-lg-start align-items-center imgContent'>
            {/* <h4 className='pb-2'>आपका और हमारा प्रयास ला सकता है</h4> */}
                <h2 className='pb-4'>ॐ कृष्णाय वासुदेवाय हरये परमात्मने प्रणतः क्लेशनाशाय गोविंदाय नमो नमः</h2>
                <Link to='/donation'  type="button" class="btn btn-primary text-white common-btn m-1">दान करें</Link>
            
                {/* <button className='btn btn-primary rounded-2 common-btn '> दान करें </button> */}
            </div>
        </div>
    </div>
  )
}

export default HomeHero

