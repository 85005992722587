import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegCalendarAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* ================== Footer Start ==================*/}
      <footer
        className='footer-area  bg-cover mt-0 pd-top-120' style={{background:"#ff7b00"}}
       
      >
    
    <div className="container-fluid p-0 m-0">
    <h2 className="text-white text-center p-4 pt-0 footerheding">गौ सेवा के लिए दान करें </h2>
    </div>

    <div className="container-fluid p-0 m-0 mb-4 py-3">
    <h5 className="text-white text-center p-4 pt-0 footerheding">हमारी परंपरा और संरक्षण का समर्थन करें।
    </h5>
    </div>

        <div className='container'>
          <div className='row'>

          <div className='col-lg-3 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='Assets/imgs/home/logo (1).png' alt='img' />
                </div>
                <div className='details'>
                  <p>
                  हमारे आध्यात्मिक गुरु, गुरुजी, आत्मा के मार्गदर्शक और ज्ञान के स्रोत हैं। वे भक्ति, ध्यान, और साधना के माध्यम से शिष्यों को आत्मिक उन्नति और जीवन के उद्देश्य की समझ प्रदान करते हैं। गुरुजी की शिक्षाएँ और मार्गदर्शन भक्तों को मानसिक शांति, आंतरिक संतुलन और आध्यात्मिक सच्चाई की ओर ले जाते हैं।  </p>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>संपर्क करें</h4>
                <div className='widget widget_contact'>
                  <ul className='details text-white'>
                    <li>
                      <FaMapMarkerAlt className='sky' />
                      गुरुकृपा गो सेवा धाम,
                      धोलेरा, अक्रूर मंदिर के पास
                    </li>
                    <li className='mt-3'>
                      <FaPhoneAlt className='sky' /> +91 70174 21499
                    </li>
                    <li className='mt-2'>
                      <FaEnvelope className='sky' /> info@ggskdham.com
                    </li>
                  </ul>
                  <ul className='social-media mt-4'>
                    <li>
                      <a href='https://www.facebook.com/shriavinash.sharan?mibextid=ZbWKwL' className='sky'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#' className='sky'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#' className='sky'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='https://youtube.com/@swamigurukripanandjimaharaj?si=XSMHlzRIQmjzO-KY' className='sky'>
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>जानकारी</h4>
                <ul>
                  <li className='sky'>
                    <Link to='/'>
                      <FaChevronRight />
                      होम
                    </Link>
                  </li>
                  <li className='sky'>
                    <Link to='/about'>
                      <FaChevronRight />हमारे बारे में
                    </Link>
                  </li>
                  <li className='sky'>
                    <Link to='/donation'>
                      <FaChevronRight />दान करें
                    </Link>
                  </li>
                  <li className='sky'>
                    <Link to='/event/completed'>
                      <FaChevronRight />
                      पूर्ण कार्यक्रम
                    </Link>
                  </li> <li className='sky'>
                    <Link to='/event/upcomming'>
                      <FaChevronRight />           
                      आगामी कार्यक्रम
                    </Link>
                  </li>
                  <li className='sky'>
                    <Link to='/gallery'>
                      <FaChevronRight /> फोटो गैलरी
                    </Link>
                  </li>
                  <li className='sky'>
                    <Link to='/contact'>
                      <FaChevronRight /> संपर्क करें
                    </Link>
                  </li>
                 
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_about'>
                <h4 className='widget-title'>हमसे जुड़े रहें</h4>
                <div className='details'>
                  {/* <p>
                  आपके अपडेट और जुड़ाव के लिए एक आसान तरीका।
                  </p> */}
                  <div className='subscribe mt-4'>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14147.464425023618!2d77.66765333555423!3d27.56666560537839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39736f75ea423989%3A0x37d16207dc56bf2c!2sAnand%20Vatika!5e0!3m2!1sen!2sin!4v1728026307123!5m2!1sen!2sin"
                   width="100%" height="200px" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <input type='text' placeholder='E-mail' />
                    <button className="bg-dark text-white">
                   <Link to='/donation'>  दान करें
                   </Link>
                    </button> */}
                    
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-12  align-self-center '>
                <p className="text-center">© गुरुसेवाकृपाधाम 2024 सर्वाधिकार सुरक्षित।</p>
              </div>
              {/* <div className='col-md-6 text-lg-end'>
                <a href='#'>Trams &amp; Condition</a>
                <a href='#'>Privacy Policy</a>
                <a href='#'>Contact Us</a>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default Footer;
