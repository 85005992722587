import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import emailjs from "emailjs-com";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Use EmailJS to send the email
    emailjs
      .send("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", formData, "YOUR_USER_ID")
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        setStatus("Message sent successfully!");
        setFormData({ name: "", phone: "", email: "", message: "" }); // Reset form fields
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        setStatus("Failed to send message.");
      });
  };

  return (
    <>
      {/*================= counter area start =================*/}
      <div className='contact-area pt-0 pb-120 '>
        <div className='container '>
          <div className='row d-flex flex-row justify-content-center align-item-center'>
            <div
              className='col-lg-6'
              data-aos='fade-left'
              data-aos-delay='200'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner ms-xl-5 p-xl-5 pt-4 '>
                <img
                  className='animate-main-img'
                  src='Assets/imgs/home/7.jpg'
                  width="100%"
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 d-flex flex-column justify-content-center align-item-center'
              data-aos='fade-right'
              data-aos-delay='200'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-5 mt-lg-0'>
                <h6 className='sub-title mt-lg-0 mt-3'>संपर्क करें</h6>
                <h2 className='title'>हमसे जुड़ें</h2>
                <p className='content'>
                  हमसे संपर्क करें और हमारे साथ जुड़कर हमारे मिशन और गतिविधियों में भाग लें।
                </p>
                <form className='mt-4' onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='single-input-inner style-border'>
                        <input
                          type='text'
                          name='name'
                          placeholder='नाम लिखें'
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='single-input-inner style-border'>
                        <input
                          type='text'
                          name='phone'
                          placeholder='नंबर लिखें'
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='single-input-inner style-border'>
                        <input
                          type='email'
                          name='email'
                          placeholder='ईमेल लिखें'
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='single-input-inner style-border'>
                        <textarea
                          name='message'
                          placeholder='संदेश'
                          value={formData.message}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-12 '>
                      <button type='submit' className='btn common-btn primary-btn text-white mt-0'>
                        संपर्क करें <FaPlus />
                      </button>
                    </div>
                    {status && (
                      <div className='col-12'>
                        <p className='mt-3'>{status}</p>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*================= counter area end =================*/}
    </>
  );
};

export default Form;

// import React from "react";
// import { FaPlus } from "react-icons/fa";

// const Form = () => {
//   return (
//     <>
//       {/*================= counter area start {/*=================*/}
//       <div className='contact-area pt-0 pb-120 '>
//         <div className='container '>
//           <div className='row d-flex flex-row justify-content-center align-item-center'>
//             <div
//               className='col-lg-6  '
//               data-aos='fade-left'
//               data-aos-delay='200'
//               data-aos-duration='1500'
//             >
//               <div className='about-thumb-inner ms-xl-5 p-xl-5 pt-4 '>
//                 <img
//                   className='animate-main-img'
//                   src='Assets/imgs/home/7.jpg' 
//                   width="100%"
//                   alt='img'
//                 />
//                 {/* <img
//                   className='main-img m-4'
//                   src='Assets/imgs/temp/cow.jpeg'
//                   alt='img'
//                 /> */}
//               </div>
//             </div>
//             <div
//               className='col-lg-6  d-flex flex-column justify-content-center align-item-center'
//               data-aos='fade-right'
//               data-aos-delay='200'
//               data-aos-duration='1500'
//             >
//               <div className='section-title mb-0 mt-5 mt-lg-0'>
//                 <h6 className='sub-title mt-lg-0 mt-3'>संपर्क करें</h6>
//                 <h2 className='title'>
//                 हमसे जुड़ें 
//                 </h2>
//                 <p className='content'>
//                 हमसे संपर्क करें और हमारे साथ जुड़कर हमारे मिशन और गतिविधियों में भाग लें।
//                 </p>
//                 <form className='mt-4'>
//                   <div className='row'>
//                     <div className='col-lg-6'>
//                       <div className='single-input-inner style-border'>
//                         <input type='text' placeholder='नाम लिखें' />
//                       </div>
//                     </div>
//                     <div className='col-lg-6'>
//                       <div className='single-input-inner style-border'>
//                         <input type='text' placeholder='नंबर लिखें' />
//                       </div>
//                     </div>
//                     <div className='col-lg-12'>
//                       <div className='single-input-inner style-border'>
//                         <input type='text' placeholder='ईमेल लिखें' />
//                       </div>
//                     </div>
//                     <div className='col-lg-12'>
//                       <div className='single-input-inner style-border'>
//                         <textarea placeholder='संदेश' defaultValue={""} />
//                       </div>
//                     </div>
//                     <div className='col-12 '>
//                       <a className='btn common-btn primary-btn text-white mt-0' href='#'>
//                       संपर्क करें <FaPlus />
//                       </a>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/*{/*================= counter area end {/*=================*/}
//     </>
//   );
// };

// export default Form;
