import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";

const PreHeader = () => {
  return (
    <>
    <div className='pre-navbar-area d-none d-md-block'>
        <div className='pre-container  space-x-4'>
        <div className='preheader pt-3'>
        <a className='px-md-3 preheader-media text-white text-center overflow-hidden'  
        href='https://wa.link/9hqphq'
        ><FaWhatsapp/> +917017421499
        </a>
        <a className='px-md-3 preheader-media text-white text-center overflow-hidden' href='tel:917017421499'><IoMdCall/> +917017421499</a>
        <a className='px-md-3 preheader-media text-white text-center overflow-hidden' href='mailto:info@ggskdham.com'> 
          <FaEnvelope/> info@ggskdham.com</a>
        </div>
        </div>
    </div>
   

    <div className='pre-navbar-area  d-md-none p-0 m-0' style={{height:"45px" }}>
        <div className='pre-container  space-x-4 p-0 m-0 px-2'>
        <div className='preheader ' style={{paddingTop:"11px"}}>
        <a className='px-2  text-white text-center overflow-hidden ' href='https://wa.link/9hqphq'  ><FaWhatsapp/> </a>
        <a className='px-2  text-white text-center overflow-hidden' href='tel:917017421499'><IoMdCall/> </a>
        <a className='px-2  text-white text-center overflow-hidden' href='mailto:info@ggskdham.com'> <FaEnvelope/> </a>
        </div>
        </div>
    </div>
   
    </>
  )
}

export default PreHeader
