import React from 'react'
import HomeHero from '../Components/HomeHero'
import GalleryCarousel from '../Components/GalleryCarousel'
import WhatWeDo from '../ComponentHeaderFooter/WhatWeDo'
import SpiritualJourney from '../Components/SpiritualJourney'
import AboutUs from '../ComponentHeaderFooter/Mission'
import TeamArea from '../Components/TeamArea'
import HomeEvent from '../Components/HomeEvent'
import Hanumanji from '../Components/Hanumanji'

const Home = () => {
  return (
  <>
    <HomeHero/>
    <AboutUs/>
    <Hanumanji/>
    <WhatWeDo/>
    <SpiritualJourney/>
    {/* <Testimonial/> */}
    <HomeEvent/>
    {/* <TeamArea/> */}
    <GalleryCarousel/>
  </>
  )
}

export default Home